import React from "react";

export default function MemberCard(props) {
  const focusHandler = (e, member) => {
    // console.log(window, "is window value");
    e.preventDefault();
    props.setFocused(member);
    // document.body.setAttribute(
    //   "style",
    //   `position: fixed; top: -${props.offset}px; left: 0; right: 0;`
    // );
    // window.scrollTo(0, newOffset);
  };

  return (
    <div key={props.member.name} className="teamCategory_cards_card">
      <div className="teamCategory_cards_card_top">
        <img
          className="teamCategory_cards_card_top_img"
          src={props.image}
          alt={props.member.name}
        />
        <div className="teamCategory_cards_card_top_titles">
          <h6>{props.member.name}</h6>
          <p>{props.member.title}</p>
        </div>
      </div>
      <div className="teamCategory_cards_card_bottom">
        <div className="about">
          {/**<h3 className="header">About:</h3>*/}
          <p className="description">{props.member.bioShort}</p>
        </div>
        <div onClick={(e) => focusHandler(e, props.member)} className="read">
          <h4>Read More</h4>
        </div>
      </div>
    </div>
  );
}
