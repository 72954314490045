import React, { useRef, useState, useEffect } from "react";
import Header from "./Header";
import StockTicker from "./StockTicker";
import Navigation from "./Nav";
import Team from "./Team";
import TeamCategory from "./TeamCategory";
import SplitView from "./SplitView";
import Modal from "./Modal";
import "./App.scss";
import { teams } from "./TeamMembers";
import Ship from "./Assets/SHIP.png";
import Truck from "./Assets/TRUCKS.png";

function App() {
  const team = useRef(null);
  const about = useRef(null);
  const contact = useRef(null);
  const [focused, setFocused] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.scrollY);
    };
  }, []);

  const scrollTo = (target) => {
    if (target === "team") {
      team.current.scrollIntoView();
    }
    if (target === "about") {
      about.current.scrollIntoView();
    }
    if (target === "contact") {
      contact.current.scrollIntoView();
    }
  };

  console.log(focused, "in app.js");
  return (
    <div className={`fullWidth aboveFold ${focused && "noScroll"}`}>
      {focused && (
        <Modal
          focused={focused}
          setFocused={setFocused}
          offset={offset}
          modalOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}

      <div className="container ">
        <Navigation scrollTo={scrollTo} />
        <Header passRef={about} />
        <Team passRef={team} />
      </div>
      {teams.map((team) => {
        return (
          <TeamCategory
            key={team.name}
            offset={offset}
            category={team.name}
            members={team.members}
            setFocused={setFocused}
          />
        );
      })}

      <SplitView
        image={Ship}
        header={"Investor relations"}
        body={"Please see the link below for our latest SEC filings."}
        link="https://www.sec.gov/edgar/browse/?CIK=1848364&owner=exclude"
        cta="View SEC Filings"
      />
      <SplitView
        image={Truck}
        reverse={true}
        header={"Contact Us"}
        passRef={contact}
        body={
          "We’ll drive your business forward. Sound interesting? Apply in the form below"
        }
        link="https://8vc.typeform.com/LITTcontact"
        cta="Contact us"
      />
      <StockTicker />
      <div className="container">
        <Navigation scrollTo={scrollTo} bottom={true} />
      </div>
    </div>
  );
}

export default App;
