import React from "react";

export default function StockTicker() {
  return (
    <section className="container container-paddingSmall">
      <div className="scroll text1">
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
        <div className="textInner">
          {" "}
          $LITTU on NASDAQ <div className="dot"></div>{" "}
        </div>
      </div>
    </section>
  );
}
