import React from "react";

export default function BackButton(props) {
  return (
    <div className="backButton" onClick={() => props.exitModal()}>
      <svg
        width="13.5"
        height="9"
        viewBox="0 0 27 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.881 8.67334H2.1853"
          stroke="white"
          stroke-width="2.05797"
          stroke-linejoin="round"
        />
        <path
          d="M9.21744 1.4707L2.01453 8.67361L9.21744 15.8765"
          stroke="white"
          stroke-width="2.05797"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
      </svg>
      <h5 className="backButton_text">Back</h5>
    </div>
  );
}
