import React from "react";

export default function CallToAction(props) {
  const { link } = props;
  return (
    <a
      href={`${link}`}
      target="_blank"
      className={`button margin-${props.margin}`}
      rel="noreferrer"
    >
      <p>{props.text}</p>
    </a>
  );
}
