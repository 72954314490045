import React from "react";

import TextColumn from "./TextColumn";

export default function SplitView(props) {
  // console.log(props.passRef && props.passRef);
  return (
    <div
      className={`containerWidth split ${props.reverse && "split_reverse"}`}
      ref={props.passRef}
    >
      <img className="split_img" src={props.image} alt={props.imageName} />
      <TextColumn
        header={props.header}
        body={props.body}
        link={props.link}
        cta={props.cta}
      />
    </div>
  );
}
