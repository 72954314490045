import React from "react";
import CallToAction from "./CallToAction";

export default function TextColumn(props) {
  return (
    <div className="textCol">
      <h1>{props.header}</h1>
      <p className="textCol_body">{props.body}</p>
      {props.link && (
        <CallToAction text={props.cta} link={props.link} margin={"top"} />
      )}
    </div>
  );
}
