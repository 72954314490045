import React from "react";
import MemberCard from "./MemberCard";
import Alan from "./Assets/Alan-Gershenhorn.png";
import Isaac from "./Assets/yitzApplbaum.png";
import Chris from "./Assets/chrisSultemeier.png";
import Andy from "./Assets/andyClarke.png";
import bruno from "./Assets/brunoSidler.png";
import Jake from "./Assets/jakeMedwell.png";
import Michael from "./Assets/michaelMcLary.png";

const imagesList = {
  "Alan Gershenhorn": Alan,
  "Isaac Applbaum": Isaac,
  "Chris Sultemeier": Chris,
  "Bruno Sidler": bruno,
  "Andy Clarke": Andy,
  "Jake Medwell": Jake,
  "Michael McLary": Michael,
};

export default function TeamCategory(props) {
  return (
    <div className="teamCategory">
      <div className="teamCategory_sticky">
        <div className="teamCategory_header containerWidth">
          <h5>{props.category}</h5>
        </div>
      </div>
      <div className="teamCategory_cards containerWidth">
        {props.members.map((member) => {
          return (
            <MemberCard
              offset={props.offset}
              member={member}
              key={member.name}
              image={imagesList[member.name]}
              setFocused={props.setFocused}
            />
          );
        })}
      </div>
    </div>
  );
}
