import React from "react";
import Logo from "./Logo";

export default function Nav(props) {
  return (
    <div className={`nav`}>
      <Logo />
      <div className={`nav_links ${props.bottom && "nav_links-bottom"}`}>
        <h5 onClick={() => props.scrollTo("about")}>About</h5>
        <h5 onClick={() => props.scrollTo("team")}>Team</h5>

        <a href="https://8vc.typeform.com/LITTcontact" className="clearLink">
          <h5>Contact</h5>
        </a>
      </div>
    </div>
  );
}
