import React from "react";
import Button from "./CallToAction";

export default function Header(props) {
  return [
    <div className="header" ref={props.passRef}>
      <h1>Logistics Innovation Technologies Corp</h1>
      <h3>
        We are a blank check company incorporated as a Delaware corporation for
        the purpose of effecting a merger, capital stock exchange, asset
        acquisition, stock purchase, reorganization or similar business
        combination with one or more businesses or entities. While we may pursue
        an initial business combination with a company in any sector or
        geography,{" "}
        <span>
          we intend to focus our search on innovation in the logistics industry,
          which includes both logistics technology companies and traditional
          industry players that can leverage technology to improve the
          efficiency of customer supply chains and their markets.
        </span>{" "}
      </h3>
      <Button
        link="https://8vc.typeform.com/LITTcontact"
        text="Contact us"
        margin={"top"}
      />
    </div>,
    <div className="disclaimer header">
      <h5 className="disclaimer_header">Disclaimer:</h5>
      <p className="disclaimer_text">
        We have not selected any potential initial business combination target
        and we have not, nor has anyone on our behalf, initiated any substantive
        discussions, directly or indirectly, with any potential initial business
        combination target.
      </p>
    </div>,
  ];
}
