import React from "react";
import UPS from "./Assets/UPS_logo.png";
import Walmart from "./Assets/Walmart-logo.png";
import Panalpina from "./Assets/PanalpinaLogoNegativeRGB 1.svg";
import eightVC from "./Assets/8VC.svg";
import Amazon from "./Assets/Amazon-logo.png";
import chRobinson from "./Assets/CHROBINSON.png";

export default function WeComeFrom() {
  //   images

  return (
    <div className="comeFrom">
      <h6>We come from:</h6>
      <div className="carousel">
        <img src={Walmart} alt="Walmart LOGO" />
        <img src={Panalpina} alt="Panalpina LOGO" />
        <img src={eightVC} alt="8VC LOGO" />
        <img src={UPS} alt="UPS LOGO" className="carousel_ups" />
        <img src={Amazon} alt="Amazon LOGO" />
        <img src={chRobinson} alt="C.H. Robinson LOGO" />
        <img src={Walmart} alt="Walmart LOGO" />
        <img src={Panalpina} alt="Panalpina LOGO" />
        <img src={eightVC} alt="8VC LOGO" />
        <img src={UPS} alt="UPS LOGO" className="carousel_ups" />
        <img src={Amazon} alt="Amazon LOGO" />
        <img src={chRobinson} alt="C.H. Robinson LOGO" />
        <img src={Walmart} alt="Walmart LOGO" />
        <img src={Panalpina} alt="Panalpina LOGO" />
        <img src={eightVC} alt="8VC LOGO" />
        <img src={UPS} alt="UPS LOGO" className="carousel_ups" />
        <img src={Amazon} alt="Amazon LOGO" />
        <img src={chRobinson} alt="C.H. Robinson LOGO" />
      </div>
    </div>
  );
}
