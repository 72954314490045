import React from "react";

export default function Logo() {
  return (
    <div className="nav_logo">
      <svg
        width="31"
        height="34"
        viewBox="0 0 31 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.08925 8.44336C6.08925 7.89107 5.64153 7.44336 5.08925 7.44336H1C0.447715 7.44336 0 7.89107 0 8.44336V32.2603C0 32.8126 0.447715 33.2603 1 33.2603H13.1694C13.7217 33.2603 14.1694 32.8126 14.1694 32.2603V28.8833C14.1694 28.331 13.7217 27.8833 13.1694 27.8833H7.08925C6.53696 27.8833 6.08925 27.4356 6.08925 26.8833V8.44336Z"
          fill="white"
        />
        <path
          d="M14.1694 15.7753C14.1694 15.223 13.7217 14.7753 13.1694 14.7753H9.08017C8.52788 14.7753 8.08017 15.223 8.08017 15.7753V24.8834C8.08017 25.4357 8.52788 25.8834 9.08017 25.8834H13.1694C13.7217 25.8834 14.1694 25.4357 14.1694 24.8834V15.7753Z"
          fill="white"
        />
        <path
          d="M16.1775 32.2603C16.1775 32.8126 16.6253 33.2603 17.1775 33.2603H21.3024C21.8547 33.2603 22.3024 32.8126 22.3024 32.2603V13.7848C22.3024 13.2325 22.7501 12.7848 23.3024 12.7848L29.2855 12.7848C29.8377 12.7848 30.2855 12.3371 30.2855 11.7848V8.44338C30.2855 7.8911 29.8377 7.44338 29.2855 7.44338L9.08017 7.44336C8.52788 7.44336 8.08017 7.89108 8.08017 8.44336V11.7848C8.08017 12.3371 8.52788 12.7848 9.08017 12.7848H15.1775C15.7298 12.7848 16.1775 13.2325 16.1775 13.7848V32.2603Z"
          fill="white"
        />
        <circle cx="11.1295" cy="3.05135" r="3.05135" fill="white" />
      </svg>
      <svg
        width="119"
        height="25"
        viewBox="0 0 119 25"
        fill="none"
        className="hide700"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.72916 0.0185547H1.40316V9.44355H7.70816V8.29955H2.72916V0.0185547Z"
          fill="white"
        />
        <path
          d="M9.39044 5.97255C9.39044 4.06155 10.4044 3.37255 11.4834 3.37255C12.5624 3.37255 13.5764 4.10055 13.5764 5.97255C13.5764 7.76655 12.5624 8.57255 11.4834 8.57255C10.4044 8.57255 9.39044 7.84455 9.39044 5.97255ZM11.4834 2.33255C9.85844 2.33255 8.12944 3.43755 8.12944 5.97255C8.12944 8.50755 9.85844 9.61255 11.4834 9.61255C13.1084 9.61255 14.8374 8.50755 14.8374 5.97255C14.8374 3.43755 13.1084 2.33255 11.4834 2.33255Z"
          fill="white"
        />
        <path
          d="M21.06 3.55455C20.631 2.81355 19.968 2.33255 18.837 2.33255C17.29 2.33255 15.873 3.59355 15.873 5.82955C15.873 8.07855 17.29 9.32656 18.837 9.32656C19.968 9.32656 20.631 8.85855 21.06 8.11755V8.78055C21.06 9.75555 20.852 10.0546 20.605 10.3276C20.28 10.6786 19.812 10.8476 19.175 10.8476C18.083 10.8476 17.693 10.3666 17.55 9.74255H16.289C16.51 11.1856 17.55 11.8876 19.162 11.8876C20.202 11.8876 21.073 11.5496 21.58 10.9646C21.983 10.5226 22.243 9.92456 22.243 8.50756V2.44955H21.06V3.55455ZM21.112 5.82955C21.112 7.59755 20.176 8.32555 19.136 8.32555C17.979 8.32555 17.16 7.45455 17.16 5.82955C17.16 4.21755 17.979 3.34655 19.136 3.34655C20.176 3.34655 21.112 4.06155 21.112 5.82955Z"
          fill="white"
        />
        <path
          d="M24.1108 2.44955V9.44355H25.2938V2.44955H24.1108ZM24.1108 0.0185547V1.44855H25.2938V0.0185547H24.1108Z"
          fill="white"
        />
        <path
          d="M32.4094 4.49055C32.1884 3.15155 31.2004 2.31955 29.6534 2.31955C28.1064 2.31955 26.8584 3.03455 26.8584 4.26955C26.8584 5.58255 27.8984 5.99855 28.8474 6.20655L29.8874 6.41455C30.6414 6.58355 31.3044 6.84356 31.3044 7.57156C31.3044 8.23456 30.5764 8.61156 29.6794 8.61156C28.6394 8.61156 28.0934 8.07855 27.8984 7.18155H26.6504C26.7934 8.40355 27.8334 9.61255 29.6534 9.61255C31.2524 9.61255 32.5004 8.83256 32.5004 7.44156C32.5004 6.10256 31.3824 5.59555 30.1084 5.36155L29.1204 5.16655C28.4444 5.02355 28.0544 4.77655 28.0544 4.21755C28.0544 3.59355 28.8344 3.33355 29.5234 3.33355C30.3554 3.33355 30.9794 3.65855 31.1744 4.49055H32.4094Z"
          fill="white"
        />
        <path
          d="M34.3385 2.44955H33.2335V3.39855H34.3385V7.68855C34.3385 9.14455 34.6505 9.44355 35.9895 9.44355H36.8475V8.45555H36.2495C35.5735 8.45555 35.5215 8.27356 35.5215 7.54556V3.39855H36.8475V2.44955H35.5215V0.694554H34.3385V2.44955Z"
          fill="white"
        />
        <path
          d="M38.3549 2.44955V9.44355H39.5379V2.44955H38.3549ZM38.3549 0.0185547V1.44855H39.5379V0.0185547H38.3549Z"
          fill="white"
        />
        <path
          d="M44.3135 2.33255C42.2335 2.33255 40.9335 3.87955 40.9335 5.95955C40.9335 8.09155 42.1815 9.61255 44.1965 9.61255C45.8345 9.61255 47.0695 8.48155 47.3165 7.16855H46.0945C45.9255 7.89655 45.2365 8.57255 44.2355 8.57255C43.1955 8.57255 42.2205 7.71455 42.2205 5.94655C42.2205 4.19155 43.1955 3.37255 44.2355 3.37255C45.1585 3.37255 45.8735 3.95755 46.0425 4.78955H47.2385C47.0695 3.59355 46.0165 2.33255 44.3135 2.33255Z"
          fill="white"
        />
        <path
          d="M53.9533 4.49055C53.7323 3.15155 52.7443 2.31955 51.1973 2.31955C49.6503 2.31955 48.4023 3.03455 48.4023 4.26955C48.4023 5.58255 49.4423 5.99855 50.3913 6.20655L51.4313 6.41455C52.1853 6.58355 52.8483 6.84356 52.8483 7.57156C52.8483 8.23456 52.1203 8.61156 51.2233 8.61156C50.1833 8.61156 49.6373 8.07855 49.4423 7.18155H48.1943C48.3373 8.40355 49.3773 9.61255 51.1973 9.61255C52.7963 9.61255 54.0443 8.83256 54.0443 7.44156C54.0443 6.10256 52.9263 5.59555 51.6523 5.36155L50.6643 5.16655C49.9883 5.02355 49.5983 4.77655 49.5983 4.21755C49.5983 3.59355 50.3783 3.33355 51.0673 3.33355C51.8993 3.33355 52.5233 3.65855 52.7183 4.49055H53.9533Z"
          fill="white"
        />
        <path
          d="M60.1374 0.0185547H58.8114V9.44355H60.1374V0.0185547Z"
          fill="white"
        />
        <path
          d="M63.3797 2.44955H62.1967V9.44355H63.3797V5.64755C63.3797 4.12655 64.0817 3.35955 65.2257 3.35955C66.5777 3.35955 66.7597 4.29555 66.7597 5.37455V9.44355H67.9427V5.02355C67.9427 3.42455 67.3317 2.33255 65.6417 2.33255C64.4587 2.33255 63.7827 2.83955 63.3797 3.50255V2.44955Z"
          fill="white"
        />
        <path
          d="M70.8954 2.44955H69.7124V9.44355H70.8954V5.64755C70.8954 4.12655 71.5974 3.35955 72.7414 3.35955C74.0934 3.35955 74.2754 4.29555 74.2754 5.37455V9.44355H75.4584V5.02355C75.4584 3.42455 74.8474 2.33255 73.1574 2.33255C71.9744 2.33255 71.2984 2.83955 70.8954 3.50255V2.44955Z"
          fill="white"
        />
        <path
          d="M78.034 5.97255C78.034 4.06155 79.048 3.37255 80.127 3.37255C81.206 3.37255 82.22 4.10055 82.22 5.97255C82.22 7.76655 81.206 8.57255 80.127 8.57255C79.048 8.57255 78.034 7.84455 78.034 5.97255ZM80.127 2.33255C78.502 2.33255 76.773 3.43755 76.773 5.97255C76.773 8.50755 78.502 9.61255 80.127 9.61255C81.752 9.61255 83.481 8.50755 83.481 5.97255C83.481 3.43755 81.752 2.33255 80.127 2.33255Z"
          fill="white"
        />
        <path
          d="M85.146 2.44955H83.833L86.472 9.44355H87.525L90.164 2.44955H88.942L87.044 7.80555L85.146 2.44955Z"
          fill="white"
        />
        <path
          d="M95.2571 6.51855C95.2571 8.16955 94.2041 8.67655 93.0991 8.67655C92.2541 8.67655 91.7471 8.23456 91.7471 7.57156C91.7471 6.60956 93.1121 6.28455 95.2571 6.07655V6.51855ZM92.8391 9.61255C94.0871 9.61255 94.8021 9.20955 95.2311 8.48155C95.2441 8.89755 95.2831 9.18355 95.3221 9.44355H96.5181C96.3751 8.78055 96.3231 7.98756 96.3361 6.80455L96.3491 5.19255C96.3621 3.20355 95.5301 2.30655 93.5671 2.30655C92.2151 2.30655 90.8631 3.15155 90.7591 4.65955H91.9811C92.0331 3.78855 92.5921 3.26855 93.5801 3.26855C94.4641 3.26855 95.1661 3.68455 95.1661 4.97155V5.14055C92.3061 5.45255 90.4341 5.94655 90.4341 7.59756C90.4341 8.88456 91.5261 9.61255 92.8391 9.61255Z"
          fill="white"
        />
        <path
          d="M98.4498 2.44955H97.3448V3.39855H98.4498V7.68855C98.4498 9.14455 98.7618 9.44355 100.101 9.44355H100.959V8.45555H100.361C99.6848 8.45555 99.6328 8.27356 99.6328 7.54556V3.39855H100.959V2.44955H99.6328V0.694554H98.4498V2.44955Z"
          fill="white"
        />
        <path
          d="M102.466 2.44955V9.44355H103.649V2.44955H102.466ZM102.466 0.0185547V1.44855H103.649V0.0185547H102.466Z"
          fill="white"
        />
        <path
          d="M106.332 5.97255C106.332 4.06155 107.346 3.37255 108.425 3.37255C109.504 3.37255 110.518 4.10055 110.518 5.97255C110.518 7.76655 109.504 8.57255 108.425 8.57255C107.346 8.57255 106.332 7.84455 106.332 5.97255ZM108.425 2.33255C106.8 2.33255 105.071 3.43755 105.071 5.97255C105.071 8.50755 106.8 9.61255 108.425 9.61255C110.05 9.61255 111.779 8.50755 111.779 5.97255C111.779 3.43755 110.05 2.33255 108.425 2.33255Z"
          fill="white"
        />
        <path
          d="M114.377 2.44955H113.194V9.44355H114.377V5.64755C114.377 4.12655 115.079 3.35955 116.223 3.35955C117.575 3.35955 117.757 4.29555 117.757 5.37455V9.44355H118.94V5.02355C118.94 3.42455 118.329 2.33255 116.639 2.33255C115.456 2.33255 114.78 2.83955 114.377 3.50255V2.44955Z"
          fill="white"
        />
        <path
          d="M3.35316 22.4436H4.67916V14.1626H7.74716V13.0186H0.285156V14.1626H3.35316V22.4436Z"
          fill="white"
        />
        <path
          d="M13.82 19.3366C13.963 17.1136 12.702 15.3326 10.557 15.3326C8.64598 15.3326 7.16398 16.7366 7.16398 18.9596C7.16398 21.0266 8.38598 22.6126 10.596 22.6126C12.299 22.6126 13.417 21.6116 13.729 20.4156H12.507C12.208 21.1046 11.636 21.5726 10.596 21.5726C9.56898 21.5726 8.47698 20.8446 8.43798 19.3366H13.82ZM10.557 16.3466C11.818 16.3466 12.533 17.3346 12.572 18.3226H8.45098C8.55498 16.9186 9.51698 16.3466 10.557 16.3466Z"
          fill="white"
        />
        <path
          d="M18.1612 15.3326C16.0812 15.3326 14.7812 16.8796 14.7812 18.9596C14.7812 21.0916 16.0292 22.6126 18.0442 22.6126C19.6822 22.6126 20.9172 21.4816 21.1642 20.1686H19.9422C19.7732 20.8966 19.0842 21.5726 18.0832 21.5726C17.0432 21.5726 16.0682 20.7146 16.0682 18.9466C16.0682 17.1916 17.0432 16.3726 18.0832 16.3726C19.0062 16.3726 19.7212 16.9576 19.8902 17.7896H21.0862C20.9172 16.5936 19.8642 15.3326 18.1612 15.3326Z"
          fill="white"
        />
        <path
          d="M23.6815 13.0186H22.4985V22.4436H23.6815V18.6476C23.6815 17.1266 24.3835 16.3596 25.5275 16.3596C26.8795 16.3596 27.0615 17.2956 27.0615 18.3746V22.4436H28.2445V18.0236C28.2445 16.4246 27.6335 15.3326 25.9435 15.3326C24.7605 15.3326 24.0845 15.8396 23.6815 16.5026V13.0186Z"
          fill="white"
        />
        <path
          d="M31.1971 15.4496H30.0141V22.4436H31.1971V18.6476C31.1971 17.1266 31.8991 16.3596 33.0431 16.3596C34.3951 16.3596 34.5771 17.2956 34.5771 18.3746V22.4436H35.7601V18.0236C35.7601 16.4246 35.1491 15.3326 33.4591 15.3326C32.2761 15.3326 31.6001 15.8396 31.1971 16.5026V15.4496Z"
          fill="white"
        />
        <path
          d="M38.3358 18.9726C38.3358 17.0616 39.3497 16.3726 40.4287 16.3726C41.5077 16.3726 42.5218 17.1006 42.5218 18.9726C42.5218 20.7666 41.5077 21.5726 40.4287 21.5726C39.3497 21.5726 38.3358 20.8446 38.3358 18.9726ZM40.4287 15.3326C38.8037 15.3326 37.0747 16.4376 37.0747 18.9726C37.0747 21.5076 38.8037 22.6126 40.4287 22.6126C42.0537 22.6126 43.7827 21.5076 43.7827 18.9726C43.7827 16.4376 42.0537 15.3326 40.4287 15.3326Z"
          fill="white"
        />
        <path
          d="M46.3807 13.0186H45.1977V22.4436H46.3807V13.0186Z"
          fill="white"
        />
        <path
          d="M49.0633 18.9726C49.0633 17.0616 50.0773 16.3726 51.1563 16.3726C52.2353 16.3726 53.2493 17.1006 53.2493 18.9726C53.2493 20.7666 52.2353 21.5726 51.1563 21.5726C50.0773 21.5726 49.0633 20.8446 49.0633 18.9726ZM51.1563 15.3326C49.5313 15.3326 47.8023 16.4376 47.8023 18.9726C47.8023 21.5076 49.5313 22.6126 51.1563 22.6126C52.7813 22.6126 54.5103 21.5076 54.5103 18.9726C54.5103 16.4376 52.7813 15.3326 51.1563 15.3326Z"
          fill="white"
        />
        <path
          d="M60.7328 16.5546C60.3038 15.8136 59.6408 15.3326 58.5098 15.3326C56.9628 15.3326 55.5458 16.5936 55.5458 18.8296C55.5458 21.0786 56.9628 22.3266 58.5098 22.3266C59.6408 22.3266 60.3038 21.8586 60.7328 21.1176V21.7806C60.7328 22.7556 60.5248 23.0546 60.2778 23.3276C59.9528 23.6786 59.4848 23.8476 58.8478 23.8476C57.7558 23.8476 57.3658 23.3666 57.2228 22.7426H55.9618C56.1828 24.1856 57.2228 24.8876 58.8348 24.8876C59.8748 24.8876 60.7458 24.5496 61.2528 23.9646C61.6558 23.5226 61.9158 22.9246 61.9158 21.5076V15.4496H60.7328V16.5546ZM60.7848 18.8296C60.7848 20.5976 59.8488 21.3256 58.8088 21.3256C57.6518 21.3256 56.8328 20.4546 56.8328 18.8296C56.8328 17.2176 57.6518 16.3466 58.8088 16.3466C59.8488 16.3466 60.7848 17.0616 60.7848 18.8296Z"
          fill="white"
        />
        <path
          d="M63.7837 15.4496V22.4436H64.9667V15.4496H63.7837ZM63.7837 13.0186V14.4486H64.9667V13.0186H63.7837Z"
          fill="white"
        />
        <path
          d="M73.0182 19.3366C73.1612 17.1136 71.9002 15.3326 69.7552 15.3326C67.8442 15.3326 66.3622 16.7366 66.3622 18.9596C66.3622 21.0266 67.5842 22.6126 69.7942 22.6126C71.4972 22.6126 72.6152 21.6116 72.9272 20.4156H71.7052C71.4062 21.1046 70.8342 21.5726 69.7942 21.5726C68.7672 21.5726 67.6752 20.8446 67.6362 19.3366H73.0182ZM69.7552 16.3466C71.0162 16.3466 71.7312 17.3346 71.7702 18.3226H67.6492C67.7532 16.9186 68.7152 16.3466 69.7552 16.3466Z"
          fill="white"
        />
        <path
          d="M79.6613 17.4906C79.4403 16.1516 78.4523 15.3196 76.9053 15.3196C75.3583 15.3196 74.1103 16.0346 74.1103 17.2696C74.1103 18.5826 75.1503 18.9986 76.0993 19.2066L77.1393 19.4146C77.8933 19.5836 78.5563 19.8436 78.5563 20.5716C78.5563 21.2346 77.8283 21.6116 76.9313 21.6116C75.8913 21.6116 75.3453 21.0786 75.1503 20.1816H73.9023C74.0453 21.4036 75.0853 22.6126 76.9053 22.6126C78.5043 22.6126 79.7523 21.8326 79.7523 20.4416C79.7523 19.1026 78.6343 18.5956 77.3603 18.3616L76.3723 18.1666C75.6963 18.0236 75.3063 17.7766 75.3063 17.2176C75.3063 16.5936 76.0863 16.3336 76.7753 16.3336C77.6073 16.3336 78.2313 16.6586 78.4263 17.4906H79.6613Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
