/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useLayoutEffect } from "react";
import BackButton from "./BackButton";
import Alan from "./Assets/Alan-Gershenhorn.png";
import Isaac from "./Assets/yitzApplbaum.png";
import Chris from "./Assets/chrisSultemeier.png";
import Andy from "./Assets/andyClarke.png";
import bruno from "./Assets/brunoSidler.png";
import Jake from "./Assets/jakeMedwell.png";
import Michael from "./Assets/michaelMcLary.png";

const imagesList = {
  "Alan Gershenhorn": Alan,
  "Isaac Applbaum": Isaac,
  "Chris Sultemeier": Chris,
  "Bruno Sidler": bruno,
  "Andy Clarke": Andy,
  "Jake Medwell": Jake,
  "Michael McLary": Michael,
};

export default function Modal(props) {
  useLockBodyScroll();

  // Hook
  function useLockBodyScroll() {
    useLayoutEffect(() => {
      // Get original body overflow
      const originalStyle = window.getComputedStyle(document.body).overflow;
      // Prevent scrolling on mount
      document.body.style.overflow = "hidden";
      // Re-enable scrolling when component unmounts
      return () => (document.body.style.overflow = originalStyle);
    }, []); // Empty array ensures effect is only run on mount and unmount
  }

  function useOutsideAlerter(ref, sideEffec) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          sideEffec(null);
          // document.body.setAttribute("style", "");
          // window.scroll({ top: props.offset, left: 0, behavior: "auto" });
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, sideEffec]);
  }

  function exitModal() {
    console.log("ran exit modal");
    props.setFocused(null);
    console.log("new focus", props.focused);
  }

  useEffect(() => {
    // if you just opened it then there's no need for this
    console.log(props.focused, "in the modal.js");
    // if (!props.focused) {
    //   document.body.setAttribute("style", "");
    //   window.scroll({ top: props.offset, left: 0, behavior: "auto" });
    // }
  });

  const wrapperRef = useRef(null);
  const containerRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.setFocused);
  // const handleScroll = (e) => console.log(e.target, "was scrolled");
  // useScrollListener(containerRef, handleScroll);

  useEffect(() => {
    if (containerRef.current) {
      //   containerRef.current.offsetTop = 0;
      //   console.log(containerRef.current);
    }
  });

  return (
    <div
      //   onClick={() => props.setModal(!props.modal)}
      className={`modal_bg modal_bg${props.focused !== null && "_toggled"}`}
      ref={containerRef}
    >
      {props.focused && (
        <div className="containerModal modalMargin">
          <div
            key={props.focused.name}
            className="teamCategory_cards_card_fixed"
            ref={wrapperRef}
          >
            <BackButton exitModal={exitModal} />
            <div className="teamCategory_cards_card_top">
              <img
                className="teamCategory_cards_card_top_img"
                src={imagesList[props.focused.name]}
                alt={props.focused.name}
              />
              <div className="teamCategory_cards_card_top_titles">
                <h6>{props.focused.name}</h6>
                <p>{props.focused.title}</p>
              </div>
            </div>
            <div className="teamCategory_cards_card_bottom teamCategory_cards_card_bottom_fixed">
              <div className="about">
                {/**<h3 className="header">About:</h3>
                 */}
                <p className="description">{props.focused.bioLong}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
