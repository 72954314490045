import React from "react";
import WeComeFrom from "./WeComeFrom";

export default function Team(props) {
  return (
    <div className="team" ref={props.passRef}>
      <h1 className="header">Our Team</h1>
      <h3 className="header">
        We have assembled an all-star team of executives which includes the
        former EVP and Chief Commercial Officer of UPS, the former CEO of
        Walmart Transportation, the former CFO of C.H. Robinson, and the former
        CEO of Panalpina. This deep sector experience uniquely positions us to
        identify, acquire, and enhance value in a high-quality transportation,
        logistics, and technology business in need of permanent capital to
        support their growth plans.
      </h3>
      <WeComeFrom />
    </div>
  );
}
